/*******************************
     User Global Variables
*******************************/
.daycy.calendar > .grid > .row > .cell.range {
  background-color: #666eb7;
}
.daycy.calendar > .grid > .row > .cell.week {
  color: #2a2f5a;
}
.daycy.calendar > .grid > .row > .cell.week.label {
  color: rgba(34, 36, 38, 0.5);
}
.daycy.calendar > .grid > .row > .cell.selected {
  background-color: #1E2033;
}
