@font-face {
    font-family: "cy-custom-icons";
    src: url("./cy-custom-icons.eot?1e474507c4cdf2d47a69abc3e823e03a?#iefix") format("embedded-opentype"),
url("./cy-custom-icons.woff2?1e474507c4cdf2d47a69abc3e823e03a") format("woff2"),
url("./cy-custom-icons.woff?1e474507c4cdf2d47a69abc3e823e03a") format("woff"),
url("./cy-custom-icons.ttf?1e474507c4cdf2d47a69abc3e823e03a") format("truetype"),
url("./cy-custom-icons.svg?1e474507c4cdf2d47a69abc3e823e03a#cy-custom-icons") format("svg");
}

i.icon.cy {
    font-family: cy-custom-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

i.icon.cy:before {
    content: "\f101";
}
